import { isUndefined } from 'lodash';

type Env = {
  [key: string]: string | undefined;
};

const env: Env = {
  realEnv: import.meta.env.MODE,
  manualEnv: import.meta.env.VITE_NODE_ENV,
  sandboxId: import.meta.env.VITE_SANDBOX_ID,
  i18nCacheKey: import.meta.env.CF_PAGES_COMMIT_SHA,
  buildCommitHash: import.meta.env.BUILD_COMMIT_SHA,
  apiBaseUrl: import.meta.env.VITE_EXPRESSABLE_API_BASE_URL,
  termsUrl: 'https://www.expressable.com/terms-and-conditions',
  privacyUrl: 'https://www.expressable.com/notice-of-privacy-practices',
  speechTermsUrl: 'https://www.expressable.com/speech-terms-and-conditions',
  privacyPolicyUrl: 'https://www.expressable.com/privacy-policy',
  attendancePolicyUrl: 'https://google.com/TODO',
  caregiverAttendancePolicyUrl: 'https://google.com/TODO',
  evaluationBailOutUrl: 'https://expressable.typeform.com/to/ogI4I4K5',
  supportEmail: 'help@expressable.io',
  insuranceSupportEmail: 'insurance@expressable.io',
  awsProjectRegion: import.meta.env.VITE_AWS_PROJECT_REGION,
  awsCognitoRegion: import.meta.env.VITE_AWS_COGNITO_REGION,
  awsUserPoolsId: import.meta.env.VITE_AWS_USER_POOLS_ID,
  awsUserPoolsWebClientId: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
  stripePublishableKeyINC: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_INC,
  stripePublishableKeySLP: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_SLP,
  stripePublishableKeyTPC: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_TPC,
  stripePublishableKeyTNJ: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_TNJ,
  stripePublishableKeySTC: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_STC,
  mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN,
  contentfulManagementToken: import.meta.env.VITE_CONTENTFUL_MANAGEMENT_API_ACCESS_TOKEN,
  contentfulExpressableReadToken: import.meta.env.VITE_CONTENTFUL_EXPRESSABLE_READ_TOKEN,
  contentfulExpressableSpaceId: import.meta.env.VITE_CONTENTFUL_EXPRESSABLE_SPACE_ID,
  contentfulExpressableEnvironmentId: import.meta.env.VITE_CONTENTFUL_EXPRESSABLE_ENVIRONMENT_ID,
  contentfulClinicalReadToken: import.meta.env.VITE_CONTENTFUL_CLINICAL_READ_TOKEN,
  contentfulClinicalSpaceId: import.meta.env.VITE_CONTENTFUL_CLINICAL_SPACE_ID,
  contentfulClinicalEnvironmentId: import.meta.env.VITE_CONTENTFUL_CLINICAL_ENVIRONMENT_ID,
  contentfulMarketingReadToken: import.meta.env.VITE_CONTENTFUL_MARKETING_READ_TOKEN,
  contentfulMarketingSpaceId: import.meta.env.VITE_CONTENTFUL_MARKETING_SPACE_ID,
  contentfulMarketingEnvironmentId: import.meta.env.VITE_CONTENTFUL_MARKETING_ENVIRONMENT_ID,
  contentfulEnvironment: import.meta.env.VITE_CONTENTFUL_ENVIRONMENT,
  apiProxyBaseUrl: import.meta.env.VITE_EXPRESSABLE_API_PROXY_URL,
  signUpAppBaseUrl: import.meta.env.VITE_SIGN_UP_BASE_URL,
  screenerAppUrl: import.meta.env.VITE_SCREENER_APP_URL,
  portalAppBaseUrl: import.meta.env.VITE_PORTAL_APP_BASE_URL,
  xstateDebugger: import.meta.env.VITE_XSTATE_DEBUGGER,
  waitlistURL: import.meta.env.VITE_WAITLIST_URL,
  schedulingApiURL: import.meta.env.VITE_SCHEDULING_API_BASE_URL,
  salesforceInsuranceURL: import.meta.env.VITE_SALESFORCE_INSURANCE_URL,
  marketingThankYouURL: import.meta.env.VITE_MARKETING_THANK_YOU_URL,
  launchDarklyClientId: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
  appBaseURL: 'app.expressable.com',
  signupManualConsultationPhoneNumber: import.meta.env.VITE_SIGNUP_MANUAL_CONSULTATION_PHONE_NUMBER,
  signupManualPhoneNumber: '5126079980',
  signupManualEmail: 'Help@expressable.io',
};

/** This should be used in order to differentiate production builds vs preview builds and Github workflow builds */
const getEnv = () => {
  if (env.realEnv === 'test' || isUndefined(env.manualEnv)) {
    return env.realEnv;
  }

  return env.manualEnv;
};

const warnForMissingKeys = () => {
  if (getEnv() !== 'development') return;

  Object.entries(env).forEach(([key, value]) => {
    if (isUndefined(value)) {
      console.warn(`[config] "${key}" is undefined`); // eslint-disable-line no-console
    }
  });
};

warnForMissingKeys();

const config = {
  get(key: string, fallback?: string): string | undefined {
    if (key === 'env') {
      return getEnv();
    }

    const value = env[key];

    if (isUndefined(value)) {
      if (getEnv() === 'development') {
        console.warn(`[config] "${key}" is undefined`); // eslint-disable-line no-console
      }
      return fallback;
    }

    return value;
  },
};

export default config;
